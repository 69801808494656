import React, {useEffect, useRef, useState} from 'react'
import Logo from '../../asset/img/logo/logo.png'
import { IoSearch } from "react-icons/io5";
import { Dropdown } from 'react-bootstrap';
import Header_Canvas from './Header_Canvas';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
const Header = () => {
  let {citypara} = useParams()
  const [city, setCity] = useState('Chandigarh')
  const navigate = useNavigate()
  useEffect(() => {
    if (citypara) {
      setCity(citypara)

    } 
  },[])
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const headerOffset =270; // Adjust this based on your header height
      const elementPosition = section.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };
  return (
    <>
      <header className='header-section padding-side  border bg-light'>
        <div className="  w-100 border-bottom p-2 mb-0  bg-red d-flex justify-content-between">
          <div className='d-flex'>
            {/* <Dropdown className='' autoClose="outside">
              <Dropdown.Toggle className=' bg-transparent  text-white fs-3 fw-bold border-0' id="dropdown-basic">
                <i className="bi bi-geo-alt"></i> {city.charAt(0).toUpperCase() + city.slice(1)}
              </Dropdown.Toggle>

              <Dropdown.Menu className='w-100'>
                <Dropdown.Item className='fs-3 border-bottom py-2 '>
                  <Dropdown className='' autoClose="outside">
                    <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                      India
                    </Dropdown.Toggle>
                        <Dropdown.Menu className='w-100'>
                          <Dropdown.Item className='fs-3 border-bottom py-2 ' onClick={() => { window.open('/chandigarh') }}>Chandigarh</Dropdown.Item>
                          <Dropdown.Item className='fs-3 border-bottom py-2'>
                            <Dropdown className=''>
                              <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                Haryana
                              </Dropdown.Toggle>
                              <Dropdown.Menu align="end" className='w-100'>
                                <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { window.open('/ambala') }}>Ambala</Dropdown.Item>
                                <Dropdown.Item className='fs-3 py-2' onClick={() => { window.open('/punchkula') }}>Panchkula</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                          </Dropdown.Item>

                          <Dropdown.Item className='fs-3 py-2'>
                            <Dropdown className=''>
                              <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                                Punjab
                              </Dropdown.Toggle>
                              <Dropdown.Menu align="end" className='w-100'>
                                <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { window.open('/amritsar') }}>Amritsar</Dropdown.Item>
                                <Dropdown.Item className='fs-3 py-2' onClick={() => { window.open('/mohali') }}>Mohali</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                  </Dropdown>
                </Dropdown.Item>
   

                <Dropdown.Item  className='fs-3 py-2'>
                  <Dropdown className=''>
                    <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                      International
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" className='w-100'>
                      <Dropdown.Item className="fs-3 border-bottom py-2" onClick={() => { window.open('/autralia') }}>Australia</Dropdown.Item>
                      <Dropdown.Item className="fs-3 border-bottom py-2" onClick={() => { window.open('/canada') }}>Canada</Dropdown.Item>
                      <Dropdown.Item className="fs-3 border-bottom py-2" onClick={() => { window.open('/newzealand') }}>New Zealand</Dropdown.Item>
                      <Dropdown.Item className="fs-3 border-bottom py-2" onClick={() => { window.open('/uk') }}>UK</Dropdown.Item>
                      <Dropdown.Item className="fs-3  py-2" onClick={() => { window.open('/usa') }}>USA</Dropdown.Item>
            
                    </Dropdown.Menu>
                  </Dropdown>
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown className='' autoClose="outside">
              <Dropdown.Toggle className=' bg-transparent  text-white fs-3 fw-bold border-0' id="dropdown-basic">
                <i className="bi bi-geo-alt"></i> {city.charAt(0).toUpperCase() + city.slice(1)}
              </Dropdown.Toggle>

              <Dropdown.Menu className='w-100'>
                <Dropdown.Item className='fs-3 border-bottom py-2 ' onClick={() => { window.open('/chandigarh') }}>Chandigarh</Dropdown.Item>
                <Dropdown.Item className='fs-3 border-bottom py-2'>
                  <Dropdown className=''>
                    <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                      Haryana
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" className='w-100'>
                      <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { window.open('/ambala') }}>Ambala</Dropdown.Item>
                      <Dropdown.Item className='fs-3 py-2' onClick={() => { window.open('/punchkula') }}>Panchkula</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </Dropdown.Item>

                <Dropdown.Item className='fs-3 py-2'>
                  <Dropdown className=''>
                    <Dropdown.Toggle className='border-0 fs-3 py-2 bg-transparent text-dark px-0 w-100 text-start' id="nested-dropdown">
                      Punjab
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" className='w-100'>
                      <Dropdown.Item className='fs-3 border-bottom py-2' onClick={() => { window.open('/amritsar') }}>Amritsar</Dropdown.Item>
                      <Dropdown.Item className='fs-3 py-2' onClick={() => { window.open('/mohali') }}>Mohali</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <h4 className='fs-3 display-pc mx-3'>|</h4>
            <h4 className='fs-3 display-pc'><i className='bi bi-envelope-fill me-2'></i>info@cnewsblast.com</h4>
          </div>
          <div className='d-flex w-sm-100'>
      
            <div className="input-group  ">
              <input type="text" className=" form-control fs-4 shadow shadow-md  search-input" placeholder="Search News" aria-describedby="basic-addon2" />
              <span className="input-group-text fs-2-sm shadow shadow-md px-4  " id="basic-addon2"><IoSearch /></span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between  border-bottom px-2 ">
          <img src={Logo} className=' image nav-pc display-mobile' />
          <button className='btn fs-2 bg-red pt-0 display-mobile'>Login</button>

        </div>

        <div className='d-flex justify-content-end align-items-start position-relative '>
          <img src={Logo} className='image position-absolute  display-pc' />
          {/* <Header_Canvas /> */}

          <div className="d-flex h-100 scroll-hidden" >
    
            <h3 className="news-topic fs-2-sm me-2 px-2" onClick={() => navigate('/')}><i className='bi bi-house-fill'></i></h3>
            <h3 className="news-topic fs-2-sm me-2 px-2" onClick={() => scrollToSection("politics")}>Politics</h3>
            <h3 className="news-topic fs-2-sm mx-2 px-2" onClick={() => scrollToSection("crime")}>Crime</h3>
            <h3 className="news-topic fs-2-sm mx-2 px-2" onClick={() => scrollToSection("travel")}>Travel</h3>
            <h3 className="news-topic fs-2-sm mx-2 px-2" onClick={() => scrollToSection("immigration")}>Immigration</h3>
            <h3 className="news-topic fs-2-sm mx-2 px-2" onClick={() => scrollToSection("sports")}>Sports</h3>
            <h3 className="news-topic fs-2-sm mx-2 px-2" onClick={() => scrollToSection("business")}>Business</h3>
            <h3 className="news-topic fs-2-sm mx-2 px-2" onClick={() => scrollToSection("events")}>Events</h3>
            <h3 className="news-topic fs-2-sm ms-2 mb-0 border-0 px-2 bg-yellow" onClick={() => scrollToSection("celebrities")}>Celebrities</h3>
          </div>
          {/* <i
            className="bi bi-arrow-right-circle-fill position-absolute fs-1 display-mobile"
            style={{ right: 10, cursor: "pointer" }}
          ></i> */}
        </div>

      </header>
    </>
  )
}

export default Header
